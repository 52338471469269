<template>

  <v-container>

    <!-- Section intro -->
    <section id="intro">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Ressources utiles
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                Vous trouverez ici de nombreuses ressources utiles pour vous informer, trouver de l’aide, communiquer, s’engager, etc.            
            </p>
            <p class="text--primary">
                Cette section du site est mise à jour régulièrement, n’hésitez pas à venir fouiller de temps en temps et nous envoyer les documents qui VOUS ont aidé s’ils ne sont pas déjà ici!            
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section aide -->
    <section id="aide">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Trouver de l'aide
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                <iframe
                src="https://drive.google.com/embeddedfolderview?id=1vRxl82GG_iV0gzf71SbDtGY38RobBjPp#grid"
                width="100%"
                height="300"
                frameborder="0"
                ></iframe>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section articles -->
    <section id="articles">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Nos articles
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                <iframe
                src="https://drive.google.com/embeddedfolderview?id=1KxCM9M50EqIcrztIcrWuGseqfmCmVnjr#grid"
                width="100%"
                height="300"
                frameborder="0"
                ></iframe>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section sensibilisation -->
    <section id="sensibilisation">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Affiches de sensibilisation
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                <iframe
                src="https://drive.google.com/embeddedfolderview?id=1nLCWKBOKADRjVyE1NaUdYzO8q9am_Ixf#grid"
                width="100%"
                height="300"
                frameborder="0"
                ></iframe>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section harcelement -->
    <section id="harcelement">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Harcèlement et violences
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                <iframe
                src="https://drive.google.com/embeddedfolderview?id=1IGZRFPy6u2zAqTC8FEcaRSnNOXuFEFlr#grid"
                width="100%"
                height="300"
                frameborder="0"
                ></iframe>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section com-inclusive -->
    <section id="com-inclusive">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Communication inclusive
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                <iframe
                src="https://drive.google.com/embeddedfolderview?id=1rwnBm-qSiNurJ6M9TrjEn7uy9jIuKGvv#grid"
                width="100%"
                height="300"
                frameborder="0"
                ></iframe>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section autre -->
    <section id="autre">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Autre
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
                <iframe
                src="https://drive.google.com/embeddedfolderview?id=1rvhWO_MO03YwH_sYqrz73wyaEApWTN6Z#grid"
                width="100%"
                height="300"
                frameborder="0"
                ></iframe>
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>


  </v-container>
</template>

<script>
  export default {
    name: 's-informer',

    data: () => ({
      
    }),
  }
</script>
